.simply-scroll-list,
.simply-scroll-list li {
    padding: 0;
    margin: 0;
    list-style: none
}

.simply-scroll,
.simply-scroll .simply-scroll-clip {
    height: 200px;
    width: 100%
}

.simply-scroll-container {
    position: relative
}

.simply-scroll-clip {
    position: relative;
    overflow: hidden
}

.simply-scroll-list {
    overflow: hidden
}

.simply-scroll {
    margin-bottom: 0
}

.simply-scroll .simply-scroll-list li {
    float: left;
    height: 200px;
    width: 250px
}

.simply-scroll-list li img {
    border: none;
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%
}

@media (min-width:600px) {
    .simply-scroll,
    .simply-scroll .simply-scroll-clip {
        height: 220px
    }
    .simply-scroll .simply-scroll-list li {
        height: 220px;
        width: 320px
    }
}

@media (min-width:768px) {
    .simply-scroll, .simply-scroll .simply-scroll-clip {
        height: 264px;
    }
    .simply-scroll .simply-scroll-list li {
        height: 280px;
        width: 350px
    }
}

@media (min-width:992px) {
    .simply-scroll,
    .simply-scroll .simply-scroll-clip {
        height: 320px
    }
    .simply-scroll .simply-scroll-list li {
        height: 320px;
        width: 479px
    }
}

@media (min-width:1200px) {
    .simply-scroll, .simply-scroll .simply-scroll-clip {
        height: 361px;
    }
    .simply-scroll .simply-scroll-list li {
        height: 376px;
        width: 479px
    }
}