@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900");

body {
    font-size: 19px;
    color: #3e3e3e;
    /* overflow: hidden; */
    overflow-x: hidden;
}
a, a:hover
{
    text-decoration: none;
}
.padd-40
{
   padding-top: 30px;
   padding-bottom: 30px;
}
.commnHeading h2 {
    font-size: 30px;
    color: rgb(243, 112, 41);
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
    margin-top: 20px;
}
/* floating menu css */

.socialArea {
    position: fixed;
    right: 10px;
    top: 170px;
    z-index: 777;
}
.socialArea .socials
{
   margin: 0;
   padding: 0;
}
.socialArea button {
    color: #fff;
    background: rgb(146, 148, 151);
    display: block;
    font-size: 19px;
    margin: 0;
    height: 45px;
    width: 45px;
    text-align: center;
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 6px;
    border: none;
}
.socialArea button svg {
    vertical-align: unset;
}
.socialArea button.facebook{
    background: #3b5998;
}
.socialArea button.twitter{
    background: #00acee;
}
.socialArea button.gplus{
    background: #d34836;
}
.socialArea button.youtube {
    background: #f61b0f;
}
/* floating menu css */
.btn-outline-secondary.comman-btn {
    color: #252525;
    border-color: #696969;
    padding: 8px 35px;
    font-weight: 500;
    outline: none;
    border-radius: 0;
      -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.btn-outline-secondary.comman-btn:hover{
  color:#fff;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.btn-outline-secondary.comman-btn:focus
{
  box-shadow: none;
}
#projects .commnHeading h2 {
    color: rgb(239, 239, 239);
}
.commnHeading h2 span {
    border: 1px solid rgb(108, 106, 106);
    padding: 1px 6px;
}
#projects .commnHeading h2 span {
    border: 1px solid #dedede;
}
.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9);
    outline: none;
}
#bottomNav .navbar-nav .nav-item.active .nav-link {
    background-color: #2460a7;
    outline: 0;
    color: #fff;
}
#topNav .navbar.navbar-expand-lg
{
   background-color: #fff;
}
#topNav .nav-item .nav-link {
    color: rgba(62, 62, 62, 0.87);
    font-weight: 500;
    border-right: 2px solid #e0e0e0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
}
#topNav .nav-item:last-child .nav-link{
   border-right: none;
   color: rgb(36, 102, 181);
}
#topNav .nav-item .nav-link i {
    color: rgb(68, 68, 68);
}
#bottomNav {
    background-color: #2f6fbb;
}
#bottomNav .navbar.navbar-expand-lg
{
    padding:0;
}
#bottomNav .navbar-nav .nav-item {
    position: relative;
    border-right: 1px solid #4386d0;
}
#bottomNav .navbar-nav .nav-item:last-child {
   border-right: none;
}
#bottomNav .navbar-nav .nav-link {
    color: #fff;
    font-size: 18px;
    padding: 15px 80px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}
#bottomNav .navbar-nav .dropdown-item {
    display: block;
    width: 100%;
    padding: 12px 22px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border-bottom: 1px solid #efefef;
}
#bottomNav .navbar-nav .dropdown-menu {
    padding: 0;
    border-radius: 0;
    top: 95%;
    font-size: 17px;
    border: 1px solid #f1f1f1;
}
#bottomNav .navbar-nav .dropdown-toggle::after {
   display: none; 
}
#bottomNav .navbar-toggler {
    color: rgba(53, 53, 53, 0.5);
    border: 1px solid #fff;
    background: #fff;
    margin: 10px;
    border-radius: 0;
}

.banner .carousel-caption {
    position: absolute;
    left: 100px;
    background: rgba(255,255,255,0.9);
    padding: 27px 34px;
    bottom: 149px;
    right: 72%;
    text-align: left;
}
.banner .carousel-caption:after {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    background-color: rgb(123, 123, 123);
    width: 45px;
    height: 2px;
}
.banner .carousel-caption:before {
    content: "";
    position: absolute;
    right: -2px;
    top: -2px;
    background-color: rgb(123, 123, 123);
    width: 2px;
    height: 45px;
}
.banner .carousel-caption h2 {
    font-size: 24px;
    color: #505050;
    font-weight: 700;
}
.banner .carousel-caption p {
    font-size: 16px;
    color: #0a0909;
    margin-bottom: 5px;
}
.banner .carousel-control-container {
    position: absolute;
    left: 91px;
    bottom: 90px;
    z-index: 99;
}
/* .banner .carousel-control-prev, .carousel-control-next {
    position: relative;
    font-size: 18px;
    opacity: 1;
    text-shadow: none;
    background: rgba(255, 255, 255, 0.9);
    color: #2f2f2f;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 8px 17px;
    border-radius: 0;
    vertical-align: middle;
    line-height: 15px;
    margin: 0px 0px 0px 9px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
} */
.banner .carousel-control-next {
    right: 0;
    left: auto;
}
.banner .btn-outline-secondary.caption-btn {
    color: #eaeaea;
    border-color: #353535;
    padding: 7px 24px;
    font-weight: 400;
    outline: none;
    font-size: 15px;
    border-radius: 0;
    background: #525252;
      -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.carousel-control-next:focus, .carousel-control-next:hover, 
.carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    opacity: 1;
}
.banner .btn-outline-secondary.caption-btn:hover {
  color: #0e0e0e;
  background: transparent;  
}

.simply-scroll-clip  li span
{
    position: relative;
    display: block;
}
.simply-scroll-clip li .overlay {
    position: absolute;
    top: 30%;
    left: 21%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 14px 40px;
    opacity: 0;
    -webkit-transition: all 0.5s;
}
.simply-scroll-clip  li .overlay p
{
    margin-bottom:0;
}
.simply-scroll-clip  li .overlay  a
{
   color:#fff;
}
.simply-scroll-clip li .overlay {
    position: absolute;
    top: 43%;
    left: 25%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 14px 24px;
    opacity: 1;
    -webkit-transition: all 0.5s;
}
/*.simply-scroll-clip li .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 14px 40px;
    opacity: 1;
    -webkit-transition: all 0.5s;
    bottom: 0;
    right: 0;
}*/
/* About us */

/* management */

.profileOuter img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    border: 5px solid #eee;
    box-shadow: 0 3px 2px rgba(171, 171, 171, 0.3);
}
.profiletxtOuter h2
{
   font-size: 22px;
}
.profiletxtOuter p i
{
   font-size: 23px;
}
.profiletxtOuter h2 span
{
   font-size: 22px;
}
/* management */


.service-area .header-text {
    text-align: center;
}
.service-area .header-text h1 {
    margin-bottom: 20px;
}
.single-service {
    background-color: #f2f2f9;
    padding: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    min-height: 211px;
    border-top: 1px solid #e4e4ff;
}
.single-service h4 {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-top: 20px;
    margin-bottom: 14px;
    font-weight: 500;
    color: #525252;
    font-size: 23px;
}
.single-service p {
   
   font-size: 16px;
}
.single-service:hover {
    box-shadow: -14.142px 14.142px 20px 0px rgba(157, 157, 157, 0.5);
    cursor: pointer;
    background-color: #ff953e;
}


/* projects Area */
#ProjectsArea {
    background: #f3f3f3;
}
#ProjectsArea .hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}
#ProjectsArea .hovereffect .overlay {
    position: absolute;
    overflow: hidden;
    width: 70%;
    height: 70%;
    left: 15%;
    top: 15%;
    border-bottom: 1px solid #FFF;
    border-top: 1px solid #FFF;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    -ms-transform: scale(0,1);
    transform: scale(0,1);
}
#ProjectsArea .hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
#ProjectsArea .hovereffect img {
    display: block;
    position: relative;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    width: 100%;
}
#ProjectsArea .hovereffect:hover img {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.6" /><feFuncG type="linear" slope="0.6" /><feFuncB type="linear" slope="0.6" /></feComponentTransfer></filter></svg>#filter');
  filter: brightness(0.6);
  -webkit-filter: brightness(0.6);
}
#ProjectsArea .hovereffect h2 {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-size: 26px;
    background-color: transparent;
    color: #FFF;
    padding: 1em 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
}
#ProjectsArea .hovereffect a, .hovereffect p {
    color: #FFF;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
}
#ProjectsArea .hovereffect:hover a, #ProjectsArea .hovereffect:hover p, #ProjectsArea .hovereffect:hover h2 {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    text-decoration: none;
}
/* project Area  */
 

/* project  */
#projects {
    position: relative;
    margin-bottom: 70px;
}
#projects {
    background: url(../images/bg-1.jpg);
    width: 100%;
    height: 280px;
    background-position: center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
#projects .OuterWrapper {
    position: relative;
}
#projects .innerWrapper {
    background: #ffffff;
    padding: 30px;
}
.c_box_item {
    position: relative;
    min-height: 102px;
    border-right: 1px solid #e2e2e2;
}
.c_box_item:last-child {

    border-right:none;
}
#projects .innerWrapper .c_box_item h4 {
    color: rgb(93, 93, 93);
    font-size: 19px;
}
#projects p {
    color: #dcdcdc;
    font-weight: 400;
}
#projects .innerWrapper .c_box_item p {
    font-size: 15px;
    color: #212121;
    font-weight: 400;
}
#projects .innerWrapper .c_box_item p a {
    color: #2466ad;
    font-weight: 500;
}
/* project  */
#bookapointmentSection {
    background: #efefef;
}
#bookapointmentSection .at-Call-left {
    float: left;
    width: 50%;
    margin-left: -5%;
    background: rgb(244, 131, 69);
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    transform: skewX(-30deg);
    overflow: hidden;
}
#bookapointmentSection .at-Call-right {
    /* padding: 95px 0 94px 73px;
    padding-right: 20%; */
    width: 50%;
    margin: auto;
}
#bookapointmentSection .at-Call-right h2 {
    color: #403f3f;
    font-size: 26px;
    font-weight: 500;
}
#bookapointmentSection .at-Call-right {
    text-align: center;
}
#bookapointmentSection .fas.fa-phone-volume {
    color: rgb(123, 123, 123);
    font-size: 60px;
}
#bookapointmentSection .at-inside-Call {
    padding: 100px 80px 100px 170px;
    text-align: right;
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
}
#bookapointmentSection .at-inside-Call h5 {
    color: #333333;
    font-size: 24px;
    line-height: 19px;
    letter-spacing: 6px;
    font-weight: 500;
}
#bookapointmentSection .at-inside-Call h2 {
    color: #ffffff;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-size: 21px;
    margin-top: 14px;
}
/* About us */

/* testimonial */
.testimonial-content
 {
    border: 1px solid #f5f5f5;
    color: #111111;
    font-size: 17px;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    padding: 43px 60px;
    position: relative;
    margin: 15px 0;
    width: 90%;
    margin: 0 auto;
}
.testimonial-content
:before {
    content: '';
    position: absolute;
    background: url(../images/leftquote.png) no-repeat;
    width: 80px;
    height: 70px;
    top: -22px;
    left: -28px;
    background-color: #ffffff;
}
.testimonial-content
:after {
    content: '';
    position: absolute;
    background: url(../images/rightsquote.png) no-repeat;
    width: 80px;
    height: 70px;
    top: auto;
    right: -28px;
    bottom: -20px;
    background-color: #ffffff;
}
.testimonial-content
 h5
{
    font-size: 15px;
    margin-top: 15px;
    color: #413e4e;
    font-weight: 600;
}
/* testimonial */


/* services css */
#services {
    background: #efefef;
}
.servicesmainOuter .serviceOuter {
    border: 1px solid #ececec;
    padding: 20px;
    text-align: center;
}
#services .main .img-wrap img
{
   margin-top: 100px;
}
#services .main .img-wrap img
{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
#services .main .image-element {
    z-index: 888;
}
#services .text-content {
    background: rgb(253, 253, 253);
    margin-top: 13px;
    margin-left: -47px;
    z-index: 99;
    padding: 31px 40px 31px 90px;
    box-shadow: 0px 7px 9px 1px rgba(0,0,0,0.12);
    border: 1px solid #e4e4e4;
}
#services .text-content h2
{
   font-size: 24px;
}
#services .text-content p {
    font-size: 16px;
}
.servicesmainOuter .serviceOuter h3 {
    font-size: 21px;
    color: rgb(243, 102, 25);
}
.servicesmainOuter .serviceOuter p {
    font-size: 16px;
}
.servicesmainOuter .serviceOuter .icnOutr {
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    width: 50px;
    box-shadow: 0px 0 11px 0 rgba(241, 241, 241, 0.7);
}
.servicesmainOuter .serviceOuter .icnOutr img
{   
   padding: 11px;
}
/* services css */


/* footerlinks css */
.bottomFooter p {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 17px;
    color: #191919;
}
.bottomFooter p a{
   color: #2466ad;
}
footer {
    background: #3a3a3a;
}
footer .footerLinks ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    margin-top: 15px;
}
footer .footerLinks ul li
{
   display: inline-block;
   padding:0 15px;
}
footer .footerLinks ul li a {
    display: inline-block;
    padding: 0 36px;
    font-size: 16px;
    color: #cacaca;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
}
footer .footerLinks ul li a:hover {
   color: #e8e8e8;
}
#scroll {
    position: fixed;
    right: 76px;
    bottom: 67px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.8313725490196079);
    text-indent: -9999px;
    display: none;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 50px;
}
#scroll span {
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-8px;
    margin-top:-12px;
    height:0;
    width:0;
    border:8px solid transparent;
    border-bottom-color:#ffffff;
}
#scroll:hover {
    background-color:#3a3a3a;
    opacity:1;filter:"alpha(opacity=100)";
    -ms-filter:"alpha(opacity=100)";
}
/* footerlinks css */


/* internal page css */
.inner-page-banner-area {
    background: url(../images/internal-bg.jpg) no-repeat;
    width: 100%;
    background-size: cover;
    height: 100px;
    margin-bottom: 0;
    position: relative;
}
.inner-page-banner-area h1 {
    font-size: 26px;
    color: #d4d4d4;
    bottom: 0;
    vertical-align: middle;
    font-weight: 400;
}
.inner-page-projectarea {
    background: url(../images/banner-2.jpg) no-repeat;
    max-width: 100%;
    background-size: cover;
    height: 780px;
    margin-bottom: 0;
    position: relative;
}
.inner-page-banner-area .breadcrumbs-area
{
    padding-top: 39px;
}
.internalArea h3 {
    font-size: 26px;
    font-weight: 600;
    color: #4a4a4a;
    position: relative;
}
.internalArea h3:before {
    content: '';
    position: absolute;
    background: #353535;
    width: 30px;
    height: 2px;
    bottom: -8px;
    margin: auto;
}
.internalArea h4 {
    font-size: 22px;
    font-weight: 500;
    color: rgb(243, 112, 41);
    margin: 22px 0;
}
.internalArea p {
    font-size: 18px;
    color: #3e3e3e;
    font-weight: 400;
}
#accordion .card-header {
    background-color: #717171;
    border-bottom: none;
    font-size: 17px;
}
.card-body
{
   font-size: 17px; 
}
#accordion .card-header a {
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    font-weight: 400;
}
.commsnListStyles
{
    margin:0;
    padding:0;
    list-style: none;
}
.commsnListStyles li {
    position: relative;
    font-size: 17px;
    padding: 9px 15px;
    margin-bottom: 15px;
    border: 1px solid #ececec;
    display: inline-block;
    width: 44%;
    margin-right: 20px;
    border-left: 2px solid #f96b1e;
    color: #3e3e3e;
    font-weight: 400;
}
.table thead th {
    vertical-align: bottom;
    font-size: 16px;
    font-weight: 500;
    background: #ec7736;
    color: #fff;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #efefef;
}
.table td {
    text-align: center;
    color: #212121;
    font-size: 16px;
    background: #f7f6f6;
    border: 1px solid #fff;
    padding: 10px 5px;
    vertical-align: middle;
}
.visionOuter, .missionOuter
{
   min-height: 370px;
}
.visionOuter {
    background: #f96b1e;
    color: #f5f5f5;
}
.visionOuter h2, .missionOuter h2 {
   font-size: 23px;
}
.missionOuter {
    background: #616161;
    color: #f5f5f5;
}
.visionOuter p, .missionOuter p {
    color: #d2d2d2;
    font-size: 16px;
    line-height: 26px;
}
.visionOuter img, .missionOuter img {
    width: 70px;
}
/* leadership */
.imgOuter {
  position: relative;
}
.imgOuter img
{
    width:100%;
}
.imgOuter .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(247, 108, 31, 0.5490196078431373);
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: .5s ease;
}
.imgOuter:hover .overlay 
{
  transform: scale(1);
}
.imgOuter .text {
    color: #3a3939;
    font-size: 22px;
    position: absolute;
    bottom: 43%;
    left: 0%;
    text-align: center;
    background: #f1f1f1;
    right: 0%;
    padding: 33px 20px;
    font-weight: 500;
}
/* leadership */


/* project pages */
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgb(232, 231, 229);
}
.btn-download {
    font-size: 16px;
    padding: 10px 28px;
    border: 1px solid #f96b1e;
    border-radius: 27px;
    text-decoration: none;
    margin-bottom: 15px;
    background: #f96b1e;
    color: #f5f5f5;
    font-weight: 500;
     -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.btn-download:hover {
  color:  #f96b1e;
  background: #fff;
  text-decoration: none;
   -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.projectHead {
    color: #484848;
    font-size: 36px;
}
.locationMap
{
    color: #f96b1e;
    font-size: 23px;
    padding-right: 10px;
}
.btn-download i
{
    padding-left: 12px;
}
.addressBox h2 {
    color: #dadada;
    font-size: 21px;
}
.addressBox h4 {
    font-size: 17px;
    color: #e0e0e0;
    font-weight: 400;
}
.addressBox {
    background: url(../images/contact-background.jpg);
    background-size: cover;
    height: 550px;
    background-position: center;
    width: 100%;
    padding: 10px 30px;
}
.addressBox p {
    color: #d2d2d2;
    font-size: 15px;
    font-weight: 400;
}
.addressBox p span
{
    display: block;
    font-weight: 500;
    margin-bottom: 12px;
}

.addressBox p a {
    color: #ff8927;
    text-decoration: none;
    background-color: transparent;
    font-size: 16px;
}
.form-horizontal.formStyle {
    background: #fff;
    padding: 30px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 1px 15px -2px rgba(102,102,102,0.2);
    min-height: 550px;
}
.form-horizontal.formStyle .btn {
    text-align: center;
    position: relative;
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    padding: 6px 0px;
    width: 134px;
    border-radius: 0px;
    background-color: #f48345;
    margin-top: 24px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}
.form-horizontal.formStyle .btn.submitBtn {
    background-color: rgb(243, 112, 41);
    outline: none;
}
.form-horizontal.formStyle .resetBtn {
    background-color: #3a3a3a;
}
.form-horizontal.formStyle .resetBtn:hover {
    background-color: #191919;
}
.form-horizontal.formStyle .btn.resetBtn:focus
{
    box-shadow:none;
}
.form-horizontal.formStyle .form-group {
    margin-bottom: 30px;
}
.form-horizontal.formStyle .form-control {
    border:none;
    border-bottom: 1px solid #ececec;
    border-radius: 0;
}
.form-horizontal.formStyle .form-control:focus {
  box-shadow: none;
  border-color: #ffc4a4;

}
/* project pages */

/* serices css */
.test-Wrap {
    border: 1px solid #ffffff;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 15px -2px rgba(102,102,102,0.2);
    padding: 20px;
    position: relative;
}
.test-Wrap h5 {
    font-size: 19px;
    font-weight: 400;
    color: rgb(216, 76, 0);
    position: relative;
}
.test-Wrap h5:before {
    content: '';
    position: absolute;
    background: #e45000;
    width: 30px;
    height: 2px;
    bottom: -8px;
    margin: auto;
}
.test-Wrap p {
    font-size: 16px;
    font-weight: 400;
}
/* serices css */


/* projects page */
.serviceWrapper .wrapperDiv {
    background: #fff;
    padding: 15px 24px;
    box-shadow: 0px 1px 15px -2px rgba(102,102,102,0.2);
}
.serviceWrapper .wrapperDiv h4 {
    font-size: 24px;
    font-weight: 500;
    color: rgb(243, 112, 41);
    border-left: 4px solid rgb(243, 112, 41);
    padding-left: 15px;
}
.serviceWrapper .wrapperDiv p {
    font-size: 16px;
    color: #3e3636;
    font-weight: 400;
}
.serviceWrapper .projectsOuter
{
  padding: 0;
  position: relative;
  overflow: hidden; 
}
.tagline {
    background: #f1f1f1;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: 500;
    display: inline-block;
    font-style: italic;
    color: #454c54;
}
.serviceWrapper .projectsOuter img
{
  padding: 0;
  position: relative;
  overflow: hidden; 
  transition: all .6s ease;
}
.projectsOuter  .sold  
{
    position: absolute;
    top: 0;
    background: rgb(218, 39, 42);
    color: #fff;
    padding: 7px 59px;
    margin-top: 52px;
}
.serviceWrapper .projectsOuter a:hover img {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transition: all .6s ease;
}
.galleryWrapper img {
    border: 1px solid #d0d0d0;
}
/* projects page */


/* baneshwar css */

#section01 {
    background: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
}
#section01 h1 {
    padding: 31px;
}
#section01 a span {
    position: absolute;
    bottom: 34px;
    left: 50%;
    width: 27px;
    height: 27px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: border-box;
}
#section-linemove-1, #section-linemove-4 {
    background: #efefef;
}
section .headtext {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 30px;
    font-weight: 600;
    color: #404040;
    position: relative;
    text-transform: uppercase;
}
#section-linemove-3 .wrapdiv
{   
   margin: 0 30px;
}
#section-linemove-3 .headtext {
    color: #f1f1f1;
}
#section-linemove-2 
{
   padding: 0 40px;
}
#section-linemove-3 h4 {
    font-size: 16px;
    color: #c7c7c7;
    font-weight: 400;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 15px;
    padding-bottom: 6px;
}
#section-linemove-3 img {
    width: 41px;
}
#section-linemove-3.bg-1 {
    background: url(../images/amenity-bg.jpg) no-repeat center center fixed;
    color: #fff;
    background-size: cover;
}
#section-linemove-3 .about-us {
    background-color: rgba(0, 0, 0, 0.35);
    text-align: left;
    color: #565656;
}
#section-linemove-2 .aminiWrap img {
    border-bottom-left-radius: 80px;
    width: 100%;
}
#section-linemove-2 span {
    font-size: 22px;
    color: #ef6115;
    margin-bottom: 20px;
    display: block;
    font-weight: 500;
    position: relative;
}
#section-linemove-2 span:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    background: #ef6368;
    bottom: -5px;
    left: 0;
}
#section-linemove-4 .floorwrapper {
    border: 1px solid #efefef;
    padding: 13px;
    background: #FFF;
    box-shadow: 9px 22px 20px 37px #f4f4f4;
}
#section-linemove-4 a {
    padding: 0px;
}
#section-linemove-4 p {
    margin-bottom: 0;
    color: #333333;
    font-size: 17px;
    margin-top: 10px;
    font-weight: 400;
}

.galleryWrapper a
{
    padding:0;
}

/*   */

/* Media Querires start here */
@media  screen and (max-width: 1680px) {


}
@media  screen and (max-width: 1440px) {
    footer .footerLinks ul li {
          padding: 0;
    }
}
@media  screen and (max-width: 1366px) {
 #bookapointmentSection .at-inside-Call {
    padding: 79px 80px 81px 94px;
}
   #bookapointmentSection .at-Call-right {
    padding: 72px 0 78px 57px;
    padding-right: 10%;
   }   
}
@media only screen and ( max-width: 1280px){
   .banner .carousel-caption {
    right: 53%;
}
  .inner-page-projectarea {
    height: 459px;
}
  #bottomNav .navbar-nav .nav-link {
      padding: 15px 51px;
  }
  footer .footerLinks ul {
    margin-top: 9px;
  }
  #section-linemove-3 h4 {
   margin-bottom: 34px;
   padding-bottom: 6px;
   line-height: 22px;
}
}


@media only screen and ( max-width: 1200px){
.banner .carousel-caption {
    right: 49%;
}
}



@media only screen and ( max-width: 1199px){
#bottomNav .navbar-nav .nav-link {
    padding: 15px 49px;
}
.banner .carousel-caption p {
    font-size: 15px;
}
.banner .carousel-caption {
    right: 39%;
}
#bookapointmentSection .at-inside-Call h5 {
    font-size: 19px;
    letter-spacing: 5px;
}
#bookapointmentSection .at-inside-Call {
    padding: 57px 74px 60px 71px;
}
#bookapointmentSection .at-Call-right h2 {
    font-size: 23px;
}
#bookapointmentSection .at-inside-Call h2 {
    font-size: 18px;
}
#bookapointmentSection .fas.fa-phone-volume {
    font-size: 42px;
}
#bookapointmentSection .at-Call-right {
    padding: 55px 0 66px 57px;
    padding-right: 9%;
}
.single-service {
    padding: 22px;
    min-height: 214px;
}
footer .footerLinks ul {
    margin-top: 8px;
}
footer .footerLinks ul li a {
     padding: 0 30px;
}
.visionOuter, .missionOuter {
    min-height: 419px;
}
.inner-page-projectarea {
    height: 490px;
}
}



@media only screen and ( max-width: 1024px){
#bottomNav .navbar-nav .nav-link {
    font-size: 15px;
}
.banner .carousel-caption {
    bottom: 67px;
}
.banner .carousel-control-container {
    bottom: 17px;
}
footer {
    background: #3a3a3a;
    padding: 10px 0;
}
footer .footerLinks ul li a {
    padding: 0 21px;
    font-size: 14px;
}
footer .footerLinks ul {
    margin-top: 5px;
}
   .bottomFooter p {
      font-size: 16px;
}
.inner-page-projectarea {
    height: 430px;
}
}

@media only screen and ( max-width: 991px){
body {
    font-size: 17px;
}
#topNav .nav-item .nav-link {
   padding-top: 2px;
   font-size: 15px;
}
#bottomNav .navbar.navbar-expand-lg {
    padding: 0px;
}
#bottomNav .navbar-nav .nav-link {
    padding: 12px 9px;
    font-size: 15px;
    border-bottom: 1px solid rgba(132, 132, 132, 0.4196078431372549);
}
#bottomNav .navbar-nav .nav-link:nth-last-of-type(2) {
     border-bottom: none;
}
#topNav .nav-item .nav-link {
   border-right: none;
}
#bottomNav .navbar-nav .nav-link {
    font-size: 14px;
    color: #f3f3f3;
}
#bottomNav .navbar-nav .nav-item.active .nav-link {
    background-color: transparent;
}
#bottomNav .navbar-nav .nav-item {
    position: relative;
    border-right: none;
}
.banner .carousel-caption {
    right: 34%;
}
.banner .carousel-caption {
   left: 52px;
   padding: 20px 25px;
}
.banner .carousel-control-container {
   left: 43px;
}
.banner .carousel-control-prev, .carousel-control-next {
    padding: 5px 14px;
}
#bookapointmentSection .at-Call-left {
    float: left;
    width: 100%;
    margin-left: 0;
    transform: skewX(0deg);
}
#bookapointmentSection .at-inside-Call {
    padding: 30px 0;
    text-align: center;
}
#bookapointmentSection .at-inside-Call {
    -ms-transform: skewX(0deg);
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
}
#bookapointmentSection .at-Call-right {
    text-align: center;
    padding: 30px 0;
    clear: both;
}
#projects .innerWrapper {
    padding: 15px;
}
#bottomNav .navbar-toggler {
    color: rgba(53, 53, 53, 0.5);
    border: 1px solid #696969;
    background: #fff;
    margin: 2px;
    border-radius: 0;
    padding: 2px 10px;
}
#services .text-content {
    margin-top: 5px;
    margin-left: -21px;
    padding: 22px 28px 21px 43px;
}
#services .text-content h2 {
    font-size: 20px;
}
#services .text-content p {
    font-size: 15px;
}
#services .text-content {
   margin-top: 5px;
   margin-left: -14px;
   padding: 22px 28px 21px 34px;
}
.btn-outline-secondary.comman-btn {
   padding: 6px 15px;
}
.commnHeading h2 {
    font-size: 27px;
    margin-bottom: 35px;
    margin-top: 0px;
}
.testimonial-content
 {
    padding: 27px 43px;
    width: 96%;
}
#projects {
    height: 340px;
}
.simply-scroll-clip li .overlay {
    left: 18%;
    text-align: center;
    margin: 0 auto;
}
.c_box_item {
    min-height: 80px;
}
.bottomlogoOuter
{
    text-align: center;
    margin-bottom: 12px;
}
footer {
    padding: 23px 0;
}
#scroll {
    right: 26px;
    bottom: 46px;
}
.inner-page-projectarea {
    height: 330px;
}
.aminitisicon
{
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px;
}
.imgOuter .text {
   font-size: 16px;
   bottom: 36%;
   padding: 23px 20px;
   }
.visionOuter, .missionOuter {
    min-height: inherit;
}
}


@media only screen and ( max-width: 768px){
.banner .carousel-caption {
   display: none;
}
.banner .carousel-control-container
{
    display: none;
}
}

@media only screen and ( max-width: 767px){
body
{
    text-align: justify;
}
.testimonial-content
 {
    padding: 39px 50px;
    width: 100%;
}
.testimonial-content
:before {
   left: -18px;
}
.testimonial-content
:after {
   right: -16px;
}
.single-service {
    padding: 22px;
    min-height: inherit;
}
.single-service {
    padding: 15px;
    min-height: inherit;
}
#services .main .img-wrap img {
    margin-top: 0px;
}
#services .text-content {
    margin-top: 0px;
    margin-left: 0;
    padding: 22px 28px 21px 34px;
    box-shadow: none;
}
.simply-scroll .simply-scroll-list li {
    height: 265px;
    width: 400px;
}
.simply-scroll, .simply-scroll .simply-scroll-clip {
    height: 263px;
}
footer .footerLinks ul li {
    padding: 4px 0;
}
#scroll {
    bottom: 108px;
}
.socialArea {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    top: inherit;
    background: #fff;
    padding-top: 5px;
}
.socialArea .socials {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
}
.socialArea button {
    display: inline-block;
    width: 24%;
    border-radius: 0;
}
.bottomFooter
{
    /* margin-bottom: 51px; */
}
#projects .innerWrapper .c_box_item h4 {
    font-size: 17px;
}
#projects .innerWrapper .c_box_item p a {
    font-weight: 400;
}
.inner-page-projectarea {
    height: 244px;
}
#section01 a span {
   bottom: 25px;
   width: 20px;
   height: 20px;
}
#section01 h1 {
    padding: 23px;
}
.projectHead {
     font-size: 30px;
}
.aminitisicon
{
    display: flex;
    flex-wrap: wrap;
}
.aminitisicon .blue
{
    order:1;
    width: 100%;
}
.aminitisicon .orange
{
    order:2;
    width: 100%;
}
.internalArea h3 {
    font-size: 24px;
}
.addressBox h2 {
    font-size: 17px;
}
.addressBox {
   padding: 10px 15px;
}
.form-horizontal.formStyle {
    padding: 15px;
    min-height: inherit;
}
}

@media only screen and ( max-width: 576px){
.simply-scroll, .simply-scroll .simply-scroll-clip {
    height: 261px;
}
.simply-scroll .simply-scroll-list li {
     height: 262px;
    width: 400px;
}
.simply-scroll-clip li .overlay {
    left: 20%;
}
.internalArea p {
    font-size: 17px;
}
}

@media only screen and ( max-width: 480px){
#bottomNav .navbar-nav .nav-link {
    font-size: 13px;
}
.commnHeading h2 {
    font-size: 23px;
    margin-bottom: 20px;
    line-height: 31px;
}
#bookapointmentSection .at-inside-Call h5 {
    font-size: 16px;
    letter-spacing: 3px;
}
#bookapointmentSection .at-inside-Call h2 {
    font-size: 16px;
}
#bookapointmentSection .fas.fa-phone-volume {
    font-size: 40px;
    display: block;
}
#bookapointmentSection .at-Call-right h2 {
    font-size: 19px;
}
.single-service h4 {
    font-size: 20px;
}
.single-service {
    padding: 10px;
}
.single-service p {
    line-height: 20px;
}
.testimonial-content
:before {
    top: -33px;
}
.testimonial-content
:after {
  bottom: -9px;   
}
.testimonial-content
 {
    padding: 31px 13px;
}
footer .footerLinks ul li {
    display: block;
}
.inner-page-projectarea {
    height: 190px;
}
#section-linemove-2 .aminiWrap img {
    border-bottom-left-radius: 0;
 }
#section-linemove-3 .wrapdiv {
   text-align: center;
    margin: 0 auto;
 }
#section-linemove-3 h4 {
    text-align: center;
  }
  #section-linemove-2 {
    padding: 0;
}
.internalArea p {
    font-size: 15px;
}
.form-horizontal.formStyle .btn {
   font-size: 17px;
   width: 100px;
}
.socialArea button {
    font-size: 13px;
    height: 34px;
    width: 45px;
  }
}

@media only screen and ( max-width: 375px){
.socialArea button {
    width: 22%;
}
.socialArea button{
   font-size: 18px;
   height: 38px;
   padding: 5px;
}
}

@media only screen and ( max-width: 320px){
.inner-page-projectarea {
    height: 135px;
}
}

/* Media Queries start here */

.phonenumber{
    border-right:2px solid #e0e0e0;
  }
.testimonial-content {
    /* height: 130px;  */
  }
  ul.control-dots {
    display: none;
}
.thumbs-wrapper.axis-vertical {
    display: none;
}
  .nav-link.active {
    background-color: #2460a7;
    outline: 0;
    color: #fff;
}
a.active {
    color: #ffffff!important;
}
  /* Regular styles */

@media (max-width: 768px) {
    /* Styles for screens with a width of 768px or more */
    /* You can add your responsive CSS rules here */
      button.navbar-toggler {
          display: none;
      }
      
      div#bottomNav div#navbarSupportedContent {
          display: contents;
      }
      
      div#bottomNav a.nav-link {
          font-size: 10px!important;
          padding: 15px 5px!important;

      }
      .to-top-progress {
            /* z-index: 999999; */
            bottom: 50px!important;
        }
  }
  @media (max-width: 450px) {
    section#bookapointmentSection .row {
        display: block;
    }
    section#bookapointmentSection svg{
        width: 100%!important;
    }
    div#topNav nav.navbar{
        text-align:center;
    }
    div#topNav a.navbar-brand {
        margin: auto;
    }   

    .phonenumber{
        border-right:none;
      }

      .testimonial-content
        :before {
            top: -33px;
            background-color: transparent;
            width: 20px;
            height: 20px;
        }
        .testimonial-content
        :after {
        bottom: -9px;   
        background-color: transparent;
        width: 20px;
            height: 20px;
        }
    
  }

 .loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}